<template>
  <v-container
    fluid
    style="
      height: 100%
    "
  >
    <v-row
      class="mb-6"
      justify="center"
      no-gutters
    >
      <v-col
        class="text-center"
        cols="12"
        md="10"
      >
        <base-material-card
          class="px-3 py-3"
          color="#039BE5"
          icon="mdi-card-account-details-outline"
        >
          <v-form v-model="valid">
            <v-text-field
              v-model="name"
              counter="60"
              :rules="[rules.noCoding]"
              label="Nome"
              clearable
              :disabled="inputed()"
              @keyup.enter="searchResult()"
            />
            <v-container>
              <v-row
                class="mu-6"
                justify="center"
                no-gutters
              />
            </v-container>
            <v-text-field
              v-model="username"
              counter="60"
              :rules="[rules.noCoding]"
              label="Email"
              :disabled="inputed()"
              clearable
              @keyup.enter="searchResult()"
            />
            <v-btn
              color="info"
              class="ml-2"
              text
              @click="searchResult()"
            >
              Buscar
            </v-btn>
          </v-form>
        </base-material-card>

        <v-expansion-panels
          v-if="getUsers.length"
        >
          <v-card
            v-for="(person, index) in getUsers"
            :key="index"
            width="100%"
            class="px-6 py-3 my-3"
          >
            <v-row no-gutters>
              <h4>
                {{ person.name }} &nbsp;&nbsp;|&nbsp;&nbsp; {{ person.username }}
              </h4>
              <user-details
                v-if="selectedUser"
                v-model="selectedUser"
                @reload="searchResult()"
              />

              <v-spacer />

              <v-btn
                color="secondary"
                outlined
                @click="openDetails(person._id)"
              >
                Abrir detalhes
              </v-btn>
            </v-row>
          </v-card>
        </v-expansion-panels>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
  import { mapActions, mapGetters } from 'vuex'
  import rules from '../../services/rules'

  import UserDetails from './components/users/UserDetails.vue'

  export default {
    name: 'ListUsers',
    components: {
      UserDetails,
    },
    data () {
      return {
        customers: [],
        // select: { id: null, name: null },
        valid: false,
        name: '',
        username: '',
        user: {},
        newPassword: '',
        show: false,
        rules: rules,
        selectedUser: null,
      }
    },
    computed: {
      ...mapGetters('users', ['getUsers']),
      ...mapGetters('customers', ['customerList']),
    },
    methods: {
      ...mapActions('users', ['findUser', 'changePassword', 'deleteUser']),
      ...mapActions('customers', ['listCustomers']),
      async searchResult () {
        if (this.name) {
          const result = await this.findUser({ name: this.name })
          if (!this.getUsers || !this.getUsers.length) {
            this.$alert('error', 'Usuário não encontrado.')
          }
          return result
        }
        if (this.username) {
          const result = await this.findUser({ username: this.username })
          if (!this.getUsers || !this.getUsers.length) {
            this.$alert('error', 'Usuário não encontrado.')
          }
          return result
        }

        this.$alert('error', 'Preencha um dos campos')
      },
      inputed () {
        if (this.name && this.username) {
          this.name = ''
          this.username = ''
          this.$alert('error', 'Preencha somente um campo')
        }
      },
      openDetails (id) {
        this.selectedUser = id
      },
    },
  }
</script>
