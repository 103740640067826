<template>
  <div>
    <!-- Deltetar Usuário -->
    <v-dialog
      v-model="deleteConfirmation"
      max-width="325"
    >
      <v-card>
        <v-card-title class="text-h5 align-center">
          Tem certeza? Não será possível recuperar esta conta.
        </v-card-title>
        <v-card-actions>
          <v-btn
            text
            color="#F44336"
            @click="deleteConfirmation=false"
          >
            Cancelar
          </v-btn>
          <v-btn
            ref="confirm"
            text
            color="#F44336"
            @click="agreeDelete()"
          >
            Confirmar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
  import { mapActions } from 'vuex'

  export default {
    name: 'DeleteUser',
    data () {
      return {
        deleteConfirmation: false,
      }
    },
    methods: {
      ...mapActions('users', ['deleteUser']),
      async delete (id) {
        if (await this.openDeleteConfirmation()) {
          this.deleteUser(id)
          this.$alert('success', 'Conta deletada')
        }
      },
      openDeleteConfirmation () {
        this.deleteConfirmation = true
        return new Promise((resolve) => {
          this.resolve = resolve
        })
      },
      agreeDelete () {
        this.resolve(true)
        this.deleteConfirmation = false
      },
      openDetails (id) {
        this.selectedUser = id
      },
    },
  }
</script>
