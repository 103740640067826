<template>
  <div>
    <v-dialog
      v-model="passwordChange"
      max-width="350"
    >
      <v-card>
        <v-card-title class="text-h5 align-center">
          <v-text-field
            v-model="newPassword"
            :rules="[rules.noCoding]"
            counter="60"
            label="Nova senha:"
            :append-icon="show ? 'mdi-eye' : 'mdi-eye-off'"
            :type="show ? 'text' : 'password'"
            hint="At least 8 characters"
            @click:append="show = !show"
          />
        </v-card-title>
        <v-card-actions>
          <v-btn
            text
            color="#F44336"
            @click="passwordChange=false"
          >
            Cancelar
          </v-btn>
          <v-btn
            origin="bottom right"
            text
            color="#F44336"
            @click="agreePassword()"
          >
            Confirmar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
  import { mapActions } from 'vuex'
  import rules from '../../../../services/rules'

  export default {
    name: 'ChangePassword',
    data () {
      return {
        newPassword: '',
        rules: rules,
        passwordChange: false,
        show: false,
      }
    },
    methods: {
      ...mapActions('users', ['changePassword']),
      async change (id) {
        if (await this.openPasswordChanger()) {
          this.changePassword([this.newPassword, id])
          this.$alert('success', 'Senha alterada com sucesso')
          this.clearInputs()
        }
      },
      openPasswordChanger () {
        this.passwordChange = true
        return new Promise((resolve) => {
          this.resolve = resolve
        })
      },
      agreePassword () {
        this.resolve(true)
        this.passwordChange = false
      },
      clearInputs () {
        this.newPassword = ''
      },
    },
  }
</script>
