export default {
  exists: (value) => {
    if (!value) {
      return 'Campo obrigatório.'
    } else { return true }
  },
  noCoding: (val) => {
    if (!val) {
      return true
    }
    const a = ['<', '/*', '>', ';', '|']
    if (a.some(el => val.toString().includes(el))) {
      return 'Por favor não insira código no formulário'
    } else {
      return true
    }
  },
  domainFormat: (val) => {
    if (!val) {
      return true
    }
    if (val.match(/^(https?:\/\/)/)) {
      if (val.match(/\/+$/)) {
        return 'Por favor não insira uma barra no final do domínio'
      } else { return true }
    } else {
      return 'Por favor formate com https:// no começo'
    }
  },
  email: (val) => {
    if (!val) {
      return true
    }
    if (!val.includes('@') || !val.includes('.')) {
      return 'Por favor insira um e-mail válido'
    } else {
      return true
    }
  },
}
