<template>
  <v-row
    justify="center"
  >
    <v-col
      cols="12"
      md="8"
    >
      <v-dialog
        v-if="userData"
        v-model="dialog"
        max-width="1200px"
        persistent
      >
        <v-card>
          <v-form autocomplete="off">
            <v-btn
              icon
              color="error"
              @click="dialog = false"
            >
              <v-icon>mdi-close</v-icon>
            </v-btn>
            <v-card-title>
              {{ userData.name }}
              <v-card-subtitle>
                {{ userData.username }}
              </v-card-subtitle>
            </v-card-title>
            <v-card-text>
              <v-row>
                <v-col>
                  <v-checkbox
                    v-model="userData.enabled"
                    color="success"
                    label="Habilitado"
                  />
                </v-col>
                <v-col cols="auto">
                  <v-btn
                    color="warning"
                    outlined
                    @click="changePassword(userData._id)"
                  >
                    Alterar senha
                  </v-btn>
                </v-col>
                <v-col cols="auto">
                  <v-btn
                    color="error"
                    outlined
                    @click="deleteUser(userData._id)"
                  >
                    Excluir
                  </v-btn>
                </v-col>
              </v-row>

              <v-row>
                <v-col>
                  <v-text-field
                    v-model="userData.name"
                    label="Nome"
                  />
                </v-col>
              </v-row>
              <v-row>
                <v-col>
                  <v-text-field
                    v-model="userData.username"
                    label="Usuário"
                    disabled
                  />
                </v-col>
              </v-row>

              <v-row>
                <v-col>
                  <v-text-field
                    v-model="userData.createdAt"
                    label="Criado em"
                    disabled
                  />
                </v-col>
                <v-col>
                  <v-text-field
                    v-model="userData.updatedAt"
                    label="Atualizado em"
                    disabled
                  />
                </v-col>
              </v-row>

              <!-- USUÁRIOS VINCULADOS ------------------------------------------------- -->

              <v-divider class="my-6" />
              <v-row no-gutters>
                <h4>Usuários vinculados</h4>
                <v-spacer />
                <v-btn
                  color="primary"
                  outlined
                  @click="addAssociatedUser"
                >
                  Adicionar
                </v-btn>
              </v-row>

              <v-card
                v-for="(associatedUser, auI) in userData.associatedClinicUsers"
                :key="`AU${auI}`"
                class="my-4 px-3"
              >
                <v-row>
                  <v-col
                    cols="6"
                    md="3"
                  >
                    <v-row
                      no-gutters
                      class="align-center"
                    >
                      <v-checkbox
                        v-model="associatedUser.enabled"
                        label="Habilitado"
                      />
                      <v-spacer />
                      <v-btn
                        color="error"
                        text
                        @click="removeAssociatedUser(associatedUser._id)"
                      >
                        Remover
                      </v-btn>
                      <v-spacer />
                    </v-row>
                  </v-col>

                  <v-col
                    cols="6"
                    md="4"
                  >
                    <v-autocomplete
                      v-model="associatedUser.customerCode"
                      :items="customerList"
                      item-text="name"
                      item-value="code"
                      label="Cliente"
                      @change="associatedUser.customerId = customerList.find(c => c.code === associatedUser.customerCode)?._id"
                    />
                  </v-col>

                  <v-col
                    cols="6"
                    md="2"
                  >
                    <v-text-field
                      v-model="associatedUser.customerCode"
                      label="Código do Cliente"
                      disabled
                    />
                  </v-col>
                  <v-col
                    cols="6"
                    md="3"
                  >
                    <v-text-field
                      v-model="associatedUser.customerId"
                      disabled
                      label="ID do Cliente"
                    />
                  </v-col>
                  <v-col
                    cols="6"
                    md="4"
                  >
                    <v-text-field
                      v-model="associatedUser.clinicUser"
                      label="Código do usuário CLINIC"
                    />
                  </v-col>
                  <v-col
                    cols="6"
                    md="4"
                  >
                    <v-text-field
                      v-model="associatedUser.clinicUsername"
                      label="Nome de exibição"
                    />
                  </v-col>
                  <v-col
                    cols="6"
                    md="4"
                  >
                    <v-select
                      v-model="associatedUser.roles"
                      label="Cargos"
                      :items="[]"
                      no-data-text="Nenhum cargo disponível"
                      multiple
                    />
                  </v-col>
                </v-row>
              </v-card>

              <!-- SEGURANÇA ------------------------------------------------- -->
              <div
                v-if="userData.security"
                class="pt-5 px-3"
              >
                <v-row no-gutters>
                  <h4>SEGURANÇA</h4>
                </v-row>

                <v-row>
                  <v-col>
                    <v-checkbox
                      v-model="userData.security.emailVerified"
                      label="Email verificado"
                    />
                  </v-col>

                  <v-col>
                    <v-text-field
                      v-model="userData.security.failedLoginAttempts"
                      label="Tentativas de login mal sucedidas"
                      hint="Contador zerado após login bem sucedido"
                      persistent-hint
                      disabled
                    />
                  </v-col>
                </v-row>
                <v-expansion-panels
                  class="mt-4"
                >
                  <v-expansion-panel>
                    <v-expansion-panel-header>
                      <h3>Dispositivos ativos</h3>
                      <h4 class="ms-3">
                        ( {{ userData.security.activeTokens.length }} dispositivos ativos )
                      </h4>
                    </v-expansion-panel-header>

                    <v-expansion-panel-content>
                      <v-card
                        v-for="(activeToken, atI) in userData.security.activeTokens"
                        :key="`AT:${atI}`"
                        class="my-4 px-3"
                      >
                        <v-row align="center">
                          <v-col
                            cols="6"
                            md="3"
                          >
                            <v-text-field
                              v-model="activeToken.key"
                              label="Chave"
                              disabled
                            />
                          </v-col>
                          <v-col
                            cols="6"
                            md="3"
                          >
                            <v-text-field
                              v-model="activeToken.issued"
                              label="Data de acesso"
                              disabled
                            />
                          </v-col>
                          <v-col
                            cols="6"
                            md="3"
                          >
                            <v-text-field
                              v-model="activeToken.expires"
                              label="Expira em"
                              disabled
                            />
                          </v-col>

                          <v-col
                            cols="6"
                            md="3"
                          >
                            <v-btn
                              color="error"
                              text
                              @click="revokeToken(activeToken._id)"
                            >
                              REVOGAR
                            </v-btn>
                          </v-col>
                        </v-row>
                      </v-card>
                    </v-expansion-panel-content>
                  </v-expansion-panel>

                  <v-expansion-panel>
                    <v-expansion-panel-header>
                      <h3>Histórico de acessos</h3>
                      <h4 class="ms-3">
                        (Últimos 3 meses) ( {{ userData.security.loginHistory.length }} tentativas )
                      </h4>
                    </v-expansion-panel-header>

                    <v-expansion-panel-content>
                      <v-card
                        v-for="(loginAttempt, latI) in userData.security.loginHistory"
                        :key="`LAT:${latI}`"
                        class="my-4 px-3"
                      >
                        <v-row align="center">
                          <v-col
                            cols="6"
                            md="3"
                          >
                            <v-text-field
                              v-model="loginAttempt.date"
                              label="Data"
                              disabled
                            />
                          </v-col>
                          <v-col
                            cols="6"
                            md="3"
                          >
                            <v-text-field
                              v-model="loginAttempt.ip"
                              label="IP"
                              disabled
                            />
                          </v-col>
                          <v-col
                            cols="6"
                            md="3"
                          >
                            <v-text-field
                              v-model="loginAttempt.userAgent"
                              label="Agente"
                              disabled
                            />
                          </v-col>

                          <v-col
                            cols="6"
                            md="3"
                          >
                            <v-card
                              :color="loginAttempt.success ? 'success' : 'error'"
                              class="text-center py-3"
                            >
                              <h4>
                                {{ loginAttempt.success ? 'Sucesso' : 'Falha' }}
                              </h4>
                            </v-card>
                          </v-col>
                        </v-row>
                      </v-card>
                    </v-expansion-panel-content>
                  </v-expansion-panel>
                </v-expansion-panels>
              </div>
            </v-card-text>
            <v-card-actions>
              <v-spacer />
              <v-btn
                color="error"
                @click="dialog = false"
              >
                Cancelar
              </v-btn>
              <v-btn
                color="success"
                @click="save"
              >
                Salvar
              </v-btn>
            </v-card-actions>
          </v-form>
        </v-card>
      </v-dialog>
    </v-col>

    <delete-user ref="deleteUser" />
    <change-password ref="changePassword" />
  </v-row>
</template>

<script>
  import { mapActions, mapGetters } from 'vuex'
  import DeleteUser from './DeleteUser.vue'
  import ChangePassword from './ChangePassword.vue'

  export default {
    name: 'UserDetails',
    components: {
      DeleteUser,
      ChangePassword,
    },
    model: {
      prop: 'modelValue',
      event: 'update:modelValue',
    },
    props: {
      modelValue: {
        type: String,
        default: undefined,
      },
    },
    emits: ['update:modelValue'],
    data: () => ({
      userData: {},
    }),
    computed: {
      ...mapGetters('customers', ['customerList']),
      dialog: {
        get () {
          return !!this.modelValue && this.userData
        },
        set (value) {
          this.$emit('update:modelValue', null)
        },
      },
    },
    mounted () {
      this.load()
    },
    methods: {
      ...mapActions('users', ['getUser', 'changePassword', 'deleteUser', 'updateUser']),
      ...mapActions('customers', ['listCustomers']),
      async load () {
        if (!this.customerList.length) {
          await this.listCustomers()
        }

        try {
          this.userData = await this.getUser(this.modelValue)
          if (!this.userData) {
            throw new Error('User not found')
          }
        } catch (e) {
          this.$alert('error', 'Usuário não encontrado')
          this.$emit('update:modelValue', null)
        }
        console.log(this.userData)
      },
      async save () {
        await this.updateUser(this.userData)
        this.$emit('update:modelValue', null)
        this.$emit('reload', true)
      },
      addAssociatedUser () {
        this.userData.associatedClinicUsers.unshift({
          enabled: true,
          customerCode: '',
          customerId: '',
          clinicUser: '',
          clinicUsername: '',
          roles: [],
        })
      },
      removeAssociatedUser (id) {
        this.userData.associatedClinicUsers = this.userData.associatedClinicUsers.filter(au => au._id !== id)
      },
      revokeToken (id) {
        this.userData.security.activeTokens = this.userData.security.activeTokens.filter(at => at._id !== id)
      },
      async deleteUser (id) {
        await this.$refs.deleteUser.delete(id)

        this.$emit('update:modelValue', null)
        this.$emit('reload', true)
      },
      async changePassword (id) {
        await this.$refs.changePassword.change(id)
      },
    },
  }
</script>
